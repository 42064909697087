import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../lib/NewText/Text';

const arrowUp = require('../../images/up-arrow.png');
const arrowDown = require('../../images/down-arrow.png');

const QuestionsSectionDesktop = ({
  styles,
  data,
  dataCount,
  intl,
  selected,
  params,
  changeSelected
}) => {
  const sortedData = Object.values(data).sort((a, b) => a.rank - b.rank);
  const questions = [].concat(...sortedData.map((s) => s.questions));

  const { locale: language } = intl;
  const renderSideMenuItem = (item, index) => {
    const { en, es, subCategories } = item;
    const selectedCategory = en === selected.category;
    const openSubCateogies = selectedCategory && Object.keys(subCategories).length > 0;

    return (
      <div key={`side-menu-${index}`}>
        <button
          type="button"
          className={styles.categoryButton}
          style={{
            marginBottom: selectedCategory ? '0.5rem' : 0,
            minWidth: 250
          }}
          onClick={() => {
            if (selectedCategory) {
              changeSelected({
                category: null,
                subCategory: null,
                questions: []
              });
            } else {
              changeSelected({
                category: en,
                subCategory: null,
                questions: []
              });
            }
          }}>
          {language === 'es' ? es : en}{' '}
          <img
            src={selectedCategory ? arrowUp.default : arrowDown.default}
            alt={selectedCategory ? 'close' : 'open'}
            style={{ marginLeft: '5px', height: '1.3rem' }}
          />
        </button>
        {openSubCateogies && (
          <div className={styles.subCategoryWrapper}>
            {Object.values(subCategories).map((cat) => {
              const selectedSubCategory = cat.en === selected.subCategory;

              return (
                <button
                  key={`${en}-${cat.en}`}
                  type="button"
                  className={[
                    styles.subCategoryButton,
                    [selectedSubCategory ? styles.selectedSubCat : '']
                  ].join(' ')}
                  onClick={() => {
                    if (selectedSubCategory) {
                      changeSelected({
                        ...selected,
                        subCategory: null,
                        questions: []
                      });
                    } else {
                      changeSelected({
                        ...selected,
                        subCategory: cat.en,
                        questions: []
                      });
                    }
                  }}>
                  {language === 'es' ? cat.es : cat.en}
                </button>
              );
            })}
          </div>
        )}
        <hr
          style={{
            borderBottom: '1px solid #92929280',
            maxWidth: '250px',
            marginLeft: 0
          }}
        />
      </div>
    );
  };

  const renderFAQTitle = () => {
    const { search } = params;
    if (search && search.length > 0) {
      if (dataCount > 0) {
        return (
          <Text className={styles.mainHeading} style={{ marginBottom: '1.5rem' }} semiSubtitle>
            <FormattedMessage id="faqs-page.resultsFound" />
            <span style={{ fontFamily: 'Montserrat Regular' }}>&quot;{search}&quot;</span>
          </Text>
        );
      }
      return (
        <Text
          className={styles.mainHeading}
          style={{ marginBottom: '1.5rem', textAlign: 'center', width: '80%' }}
          semiSubtitle>
          <FormattedMessage id="faqs-page.notFoundTitle" />
          <span style={{ fontFamily: 'Montserrat Regular' }}>&quot;{search}&quot;</span>
        </Text>
      );
    }
    return (
      <Text className={styles.mainHeading} style={{ marginBottom: '1.5rem' }} semiSubtitle>
        <FormattedMessage id="faqs-page.faq" />
      </Text>
    );
  };

  const renderQuestions = (item, index, lastIndex) => {
    const { questionen, questiones, answeren, answeres } = item;
    const selectedQuestion = selected.questions.includes(questionen);
    return (
      <div className={styles.questionWrapper} key={`${item.questionen}-${index}`}>
        <button
          type="button"
          className={styles.questionButton}
          onClick={() => {
            if (selectedQuestion) {
              changeSelected({
                ...selected,
                questions: [...selected.questions.filter((q) => q !== questionen)]
              });
            } else {
              changeSelected({
                ...selected,
                questions: [...selected.questions, questionen]
              });
            }
          }}>
          {language === 'es' ? questiones : questionen}{' '}
          <img
            src={selectedQuestion ? arrowUp.default : arrowDown.default}
            alt={selectedQuestion ? 'close' : 'open'}
            style={{ marginLeft: '5px', height: '1.1rem' }}
          />
        </button>
        {selectedQuestion && (
          <div className={styles.answerWrapper}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: language === 'es' ? answeres : answeren
              }}
            />
          </div>
        )}
        {!lastIndex && <hr style={{ borderBottom: '1px solid #92929280' }} />}
      </div>
    );
  };

  const renderContactUs = () => (
    <Text className={styles.contactUs}>
      <FormattedMessage id="faqs-page.contactUs" />
      <br />
      <a
        href={`mailto:${intl.formatMessage({
          id: `faqs-page.supportMail`
        })}`}
        target="_blank"
        rel="noreferrer">
        <FormattedMessage id="faqs-page.supportMail" />
      </a>
    </Text>
  );

  return (
    <div className={`container ${styles.dataWrapper}`} style={{ padding: '3rem 1.5rem' }}>
      <div>{sortedData.map((item, i) => renderSideMenuItem(item, i))}</div>
      <div className={styles.sectionWrapper}>
        {renderFAQTitle()}
        {dataCount > 0
          ? questions.map((item, i) => {
            const lastIdx = i === questions.length - 1;
            return renderQuestions(item, i, lastIdx);
          })
          : renderContactUs()}
      </div>
    </div>
  );
};

QuestionsSectionDesktop.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  dataCount: PropTypes.number.isRequired,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  params: PropTypes.objectOf(PropTypes.string).isRequired,
  selected: PropTypes.objectOf(PropTypes.string).isRequired,
  changeSelected: PropTypes.func.isRequired
};

export default QuestionsSectionDesktop;

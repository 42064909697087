import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes from 'prop-types';

const Loader = ({ styles, isLoading }) => (
  <div
    className={styles.dataWrapper}
    style={{
      display: 'flex',
      padding: '1.5rem',
      justifyContent: 'center'
    }}
  >
    <ClipLoader color='#3F5C83' loading={isLoading} size={50} />
  </div>
);

Loader.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default Loader;

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { injectIntl } from 'gatsby-plugin-intl';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { PageProps } from 'gatsby';
import {
  Loader,
  QuestionsSectionDesktop,
  QuestionsSectionMobile,
  SearchSection
} from '../components/faqs';
import Layout from '../components/ui/layout';
import SEO from '../components/ui/seo';

import DefaultLanguage from '../hoc/default-language';
import { fetchAllFAQs } from '../services/faqs';
import * as styles from '../style/pages/faqs.module.scss';

const INITIAL_DATA = {
  Users: {
    rank: 1,
    en: 'Users',
    es: 'Creadores',
    subCategories: {},
    questions: []
  },
  Companies: {
    rank: 2,
    en: 'Companies',
    es: 'Compañías',
    subCategories: {},
    questions: []
  }
};

const INITIAL_SELECTED = {
  category: 'Users',
  subCategory: 'General',
  questions: []
};

const SEARCH_SELECTED = {
  category: null,
  subCategory: null,
  questions: []
};

const FAQS = (props) => {
  const { pageContext, location, intl } = props;
  const { language } = pageContext;
  const breakpoints = useBreakpoint();

  const [initData, setInitData] = useState(JSON.parse(JSON.stringify(INITIAL_DATA)));
  const [data, setData] = useState(JSON.parse(JSON.stringify(INITIAL_DATA)));
  const [dataCount, setDataCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [params, setParams] = useState({});
  const [selected, changeSelected] = useState(JSON.parse(JSON.stringify(INITIAL_SELECTED)));

  const intialSaveData = async (allData = [], searching) => {
    let transformedData = JSON.parse(JSON.stringify(INITIAL_DATA));
    let newDataCount = 0;
    const selectedQuestions = [];
    if (allData && allData.length > 0) {
      allData.forEach((row) => {
        const {
          faqs: { categoryen, categoryes, subcategoryen, subcategoryes, questionen }
        } = row;
        newDataCount += 1;
        if (Object.prototype.hasOwnProperty.call(transformedData, categoryen)) {
          transformedData[categoryen].questions.push(row.faqs);

          const subCat = transformedData[categoryen].subCategories;
          if (!Object.prototype.hasOwnProperty.call(subCat, subcategoryen)) {
            transformedData[categoryen].subCategories[subcategoryen] = {
              en: subcategoryen,
              es: subcategoryes
            };
          }
        } else {
          transformedData[categoryen] = {
            en: categoryen,
            es: categoryes,
            subCategories: {
              [subcategoryen]: {
                en: subcategoryen,
                es: subcategoryes
              }
            },
            questions: [row.faqs]
          };
        }
        if (searching) {
          selectedQuestions.push(questionen);
        }
      });
    } else {
      transformedData = JSON.parse(JSON.stringify(INITIAL_DATA));
    }

    let intialSelectedQuestions = transformedData[INITIAL_SELECTED.category].questions
      .filter((item) => item.subcategoryen === INITIAL_SELECTED.subCategory)
      .map((item) => item.questionen);

    if (intialSelectedQuestions.length > 0) {
      intialSelectedQuestions = [intialSelectedQuestions[0]];
    }

    changeSelected({
      ...JSON.parse(JSON.stringify(INITIAL_SELECTED)),
      questions: [...intialSelectedQuestions]
    });
    setInitData(transformedData);
    setData(transformedData);
    setDataCount(newDataCount);
  };

  const saveData = async (allData = [], searching) => {
    let transformedData = JSON.parse(JSON.stringify(INITIAL_DATA));
    let newDataCount = 0;
    const selectedQuestions = [];

    if (allData && allData.length > 0) {
      allData.forEach((row) => {
        const {
          faqs: { categoryen, categoryes, subcategoryen, subcategoryes, questionen }
        } = row;
        newDataCount += 1;
        if (Object.prototype.hasOwnProperty.call(transformedData, categoryen)) {
          transformedData[categoryen].questions.push(row.faqs);

          const subCat = transformedData[categoryen].subCategories;
          if (!Object.prototype.hasOwnProperty.call(subCat, subcategoryen)) {
            transformedData[categoryen].subCategories[subcategoryen] = {
              en: subcategoryen,
              es: subcategoryes
            };
          }
        } else {
          transformedData[categoryen] = {
            en: categoryen,
            es: categoryes,
            subCategories: {
              [subcategoryen]: {
                en: subcategoryen,
                es: subcategoryes
              }
            },
            questions: [row.faqs]
          };
        }
        if (searching) {
          selectedQuestions.push(questionen);
        }
      });
    } else {
      transformedData = JSON.parse(JSON.stringify(INITIAL_DATA));
    }

    changeSelected({
      ...JSON.parse(JSON.stringify(SEARCH_SELECTED)),
      questions: [...selectedQuestions]
    });
    setInitData(transformedData);
    setData(transformedData);
    setDataCount(newDataCount);
  };

  const fetchData = async (inParams) => {
    setLoading(true);

    // changeSelected(JSON.parse(JSON.stringify(INITIAL_SELECTED)));
    await fetchAllFAQs(inParams)
      .then(async (faqs) => {
        if (faqs.length > 0) {
          await intialSaveData(faqs, !!params.search);
        }
      })
      .catch(async () => {
        await intialSaveData([]);
      })
      .finally(async () => {
        await setLoading(false);
      });
  };

  const searchData = async (inParams) => {
    setLoading(true);
    changeSelected(JSON.parse(JSON.stringify(SEARCH_SELECTED)));
    await fetchAllFAQs(inParams)
      .then(async (response = []) => {
        await saveData(response, !!params.search);
      })
      .catch(async () => {
        await saveData([]);
      })
      .finally(async () => {
        await setLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((inParams) => {
      searchData(inParams);
    }, 1000),
    []
  );

  const onCategoryChange = () => {
    if (!selected.category) {
      setData(initData);
    } else {
      const filteredData = JSON.parse(JSON.stringify(initData));

      Object.keys(filteredData).forEach((key) => {
        const val = filteredData[key];
        if (key === selected.category) {
          val.questions = [...val.questions];
        } else {
          val.questions = [];
        }
      });
      setData(filteredData);
    }
  };

  const onSubCategoryChange = () => {
    if (!selected.subCategory) {
      onCategoryChange();
    } else {
      const filteredData = JSON.parse(JSON.stringify(initData));
      Object.keys(filteredData).forEach((key) => {
        const val = filteredData[key];
        if (key === selected.category) {
          val.questions = val.questions.filter((q) => q.subcategoryen === selected.subCategory);
        } else {
          val.questions = [];
        }
      });
      setData(filteredData);
    }
  };

  useEffect(() => {
    fetchData(params).then(() => {
      // onSubCategoryChange()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.search !== null && params.search !== undefined) {
      debounceSearch(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.search]);

  useEffect(() => {
    onSubCategoryChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.subCategory, initData]);

  useEffect(() => {
    onCategoryChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.category]);

  const onSearchChange = async (e) => {
    const text = e.target.value;
    await setParams({ ...params, search: text });
  };

  const renderQuestions = () => {
    if (breakpoints.md) {
      return (
        <QuestionsSectionMobile
          data={data}
          dataCount={dataCount}
          styles={styles}
          intl={intl}
          selected={selected}
          params={params}
          changeSelected={changeSelected}
        />
      );
    }
    return (
      <QuestionsSectionDesktop
        data={data}
        dataCount={dataCount}
        styles={styles}
        intl={intl}
        selected={selected}
        params={params}
        changeSelected={changeSelected}
      />
    );
  };

  return (
    <>
      <SEO page="faqs" language={pageContext.language} />
      <DefaultLanguage location={location}>
        <Layout location={location} language={language} page="faqs">
          <>
            <SearchSection onSearchChange={onSearchChange} intl={intl} styles={styles} />
            {isLoading && <Loader isLoading={isLoading} styles={styles} />}
            {!isLoading && renderQuestions()}
          </>
        </Layout>
      </DefaultLanguage>
    </>
  );
};

FAQS.propTypes = {
  pageContext: PropTypes.instanceOf(PageProps).isRequired,
  location: PropTypes.instanceOf(PageProps).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired
  }).isRequired
};

export default injectIntl(FAQS);

import { FormattedMessage } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Text from '../../lib/NewText/Text';

const arrowUp = require('../../images/up-arrow.png');
const arrowDown = require('../../images/down-arrow.png');

const QuestionsSectionMobile = ({
  styles,
  data,
  dataCount,
  intl,
  selected,
  params,
  changeSelected
}) => {
  const sortedData = Object.values(data).sort((a, b) => a.rank - b.rank);
  const { locale: language } = intl;

  useEffect(() => {
    changeSelected({
      category: 'Users',
      subCategory: null,
      questions: selected.questions ? [...selected.questions] : []
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderQuestions = (item, index, lastIndex) => {
    const { questionen, questiones, answeren, answeres } = item;
    const selectedQuestion = selected.questions.includes(questionen);

    return (
      <div className={styles.questionWrapperMob} key={`${item.questionen}-${index}`}>
        <button
          type="button"
          className={styles.questionButtonMob}
          onClick={() => {
            if (selectedQuestion) {
              changeSelected({
                ...selected,
                questions: [...selected.questions.filter((q) => q !== questionen)]
              });
            } else {
              changeSelected({
                ...selected,
                questions: [...selected.questions, questionen]
              });
            }
          }}>
          {language === 'es' ? questiones : questionen}{' '}
          <img
            src={selectedQuestion ? arrowUp.default : arrowDown.default}
            alt={selectedQuestion ? 'close' : 'open'}
            style={{ marginLeft: '5px', height: '0.9rem' }}
          />
        </button>
        {selectedQuestion && (
          <div className={styles.answerWrapperMob}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: language === 'es' ? answeres : answeren
              }}
            />
          </div>
        )}
        {!lastIndex && <hr style={{ borderBottom: '1px solid #92929280' }} />}
      </div>
    );
  };

  const renderSideMenuItem = (item, index) => {
    const { en, es, questions } = item;
    const selectedCategory = en === selected.category || selected.questions.length > 0;

    return (
      <div key={`side-menu-${index}`}>
        <button
          type="button"
          className={styles.categoryButtonMob}
          style={{ marginBottom: selectedCategory ? '1.5rem' : 0 }}
          onClick={() => {
            if (selectedCategory) {
              changeSelected({
                category: null,
                subCategory: null,
                questions: []
              });
            } else {
              changeSelected({
                category: en,
                subCategory: null,
                questions: []
              });
            }
          }}>
          {language === 'es' ? es : en}{' '}
          <img
            src={selectedCategory ? arrowUp.default : arrowDown.default}
            alt={selectedCategory ? 'close' : 'open'}
            style={{ marginLeft: '5px', height: '1.2rem' }}
          />
        </button>
        {selectedCategory &&
          questions.map((qItem, i) => renderQuestions(qItem, i, i === questions.length - 1))}
        <hr style={{ borderBottom: '1px solid #92929280' }} />
      </div>
    );
  };

  const renderFAQTitle = () => {
    const { search } = params;
    if (search && search.length > 0) {
      if (dataCount > 0) {
        return (
          <Text className={styles.mainHeadingMob} style={{ marginBottom: '1.5rem' }} semiSubtitle>
            <FormattedMessage id="faqs-page.faq" />
          </Text>
          // <Text
          //   className={styles.mainHeadingMob}
          //   style={{ marginBottom: '1.5rem' }}
          //   semiSubtitle
          // >
          //   <FormattedMessage id='faqs-page.resultsFound' />
          //   <span style={{ fontFamily: 'Montserrat Regular' }}>
          //     &quot;{search}&quot;
          //   </span>
          // </Text>
        );
      }
      return (
        <Text
          className={styles.mainHeadingMob}
          style={{ marginBottom: '1.5rem', textAlign: 'center' }}
          semiSubtitle>
          <FormattedMessage id="faqs-page.notFoundTitle" />
          <span style={{ fontFamily: 'Montserrat Regular' }}>&quot;{search}&quot;</span>
        </Text>
      );
    }
    return (
      <Text className={styles.mainHeadingMob} style={{ marginBottom: '1.5rem' }} semiSubtitle>
        <FormattedMessage id="faqs-page.faq" />
      </Text>
    );
  };

  const renderContactUs = () => (
    <Text className={styles.contactUsMob}>
      <FormattedMessage id="faqs-page.contactUs" />
      <br />
      <a
        href={`mailto:${intl.formatMessage({
          id: `faqs-page.supportMail`
        })}`}
        target="_blank"
        rel="noreferrer">
        <FormattedMessage id="faqs-page.supportMail" />
      </a>
    </Text>
  );

  return (
    <div className={styles.dataWrapperMob}>
      {renderFAQTitle()}
      {dataCount <= 0
        ? renderContactUs()
        : sortedData.map((item, i) => renderSideMenuItem(item, i))}
    </div>
  );
};

QuestionsSectionMobile.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  dataCount: PropTypes.number.isRequired,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  params: PropTypes.objectOf(PropTypes.string).isRequired,
  selected: PropTypes.objectOf(PropTypes.string).isRequired,
  changeSelected: PropTypes.func.isRequired
};

export default QuestionsSectionMobile;

// extracted by mini-css-extract-plugin
export var answerWrapper = "faqs-module--answerWrapper--a5508";
export var answerWrapperMob = "faqs-module--answerWrapperMob--e4ea7";
export var categoryButton = "faqs-module--categoryButton--18e1d";
export var categoryButtonMob = "faqs-module--categoryButtonMob--a22a8";
export var contactUs = "faqs-module--contactUs--95874";
export var contactUsMob = "faqs-module--contactUsMob--5f1fd";
export var dataWrapper = "faqs-module--dataWrapper--14395";
export var dataWrapperMob = "faqs-module--dataWrapperMob--315f7";
export var mainHeading = "faqs-module--mainHeading--3802b";
export var mainHeadingMob = "faqs-module--mainHeadingMob--989ee";
export var mainRow = "faqs-module--mainRow--01823";
export var mobileFaqSearchSection = "faqs-module--mobileFaqSearchSection--b44e0";
export var questionButton = "faqs-module--questionButton--d0bb9";
export var questionButtonMob = "faqs-module--questionButtonMob--ea6dd";
export var questionWrapper = "faqs-module--questionWrapper--46a5c";
export var questionWrapperMob = "faqs-module--questionWrapperMob--6126c";
export var searchBarWrapper = "faqs-module--searchBarWrapper--ad314";
export var sectionWrapper = "faqs-module--sectionWrapper--2f7cc";
export var selectedSubCat = "faqs-module--selectedSubCat--fbe3b";
export var subCategoryButton = "faqs-module--subCategoryButton--84436";
export var subCategoryWrapper = "faqs-module--subCategoryWrapper--580d5";
export var subHeading = "faqs-module--subHeading--bfb7c";
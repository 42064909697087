import { FormattedMessage } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import Text from '../../lib/NewText/Text';

const SearchSection = ({ onSearchChange, intl, styles }) => {
  const mainClasses = [styles.mainRow, 'container-fluid', styles.mobileFaqSearchSection].join(' ');

  return (
    <div className={mainClasses}>
      <Text className={styles.mainHeading} title>
        <FormattedMessage id='faqs-page.title' />
      </Text>
      <Text className={styles.subHeading} body>
        <FormattedMessage id='faqs-page.subtitle' />
      </Text>
      <div className={styles.searchBarWrapper}>
        <i
          className='fa fa-search'
          style={{
            color: '#929292',
            fontSize: '18px',
            marginRight: '1em',
            marginLeft: '1em'
          }}
        />
        <input
          type='search'
          onChange={onSearchChange}
          placeholder={`${intl.formatMessage({
            id: `faqs-page.searchPH`
          })}`}
        />
      </div>
    </div>
  );
};

SearchSection.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  styles: PropTypes.objectOf(PropTypes.string).isRequired
};

export default SearchSection;

import axios from 'axios';

const wordPressURL = process.env.GATSBY_WORDPRESS_REST_API_V2;
const fetchAllFAQsQuery = `query MyQuery {
    faqs (where: {search: "$search"}, first: 200, after: "$after") {
      pageInfo {
        endCursor,
        hasNextPage 
      }
      nodes {
        faqs {
          rank
          answeren
          answeres
          categoryen
          categoryes
          questionen
          questiones
          subcategoryen
          subcategoryes
        }
      }
    }
}
`;

const faqAPICall = async (params, after) => {
  const { search = '' } = params;
  const query = fetchAllFAQsQuery.replace('$search', search).replace('$after', after);

  const faqs = await axios.post(wordPressURL, { query }).catch((error) => {
    throw new Error({ error });
  });
  const { nodes, pageInfo } = faqs.data.data.faqs;

  return { nodes, pageInfo };
};

const fetchAllFAQs = async (params = {}) => {
  let allNodes = [];
  const { nodes, pageInfo } = await faqAPICall(params);

  if (pageInfo.hasNextPage) {
    const { nodes: newNodes } = await faqAPICall(params, pageInfo.endCursor);
    allNodes = [...allNodes, ...newNodes];
  }

  return [...allNodes, ...nodes];
};

export { fetchAllFAQs };
